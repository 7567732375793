.Favourites-Container {
    text-align: left;
    padding: 0px 15px;
    margin-top: 10px;
}

.Favourites-Container h2 {
    font: var(--heading-font);
    padding-top: 29px;
    margin-top: 0;
}

.Favourites-Container h3 {
    font: var(--regular-font);
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: var(--text-heading-colour);
    text-align: left;
    margin: 14px 0;
}

.Favourites-Container .Tags-List {
    text-align: left;
    width: 339px;
    margin: auto;
}

.Favourites-Container .FavChaptersList {
    width: 332px;
    margin: auto;
}

.Favourites-Container .FavChaptersList .FavChapterListItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 10px;
    padding-bottom: 20px;
}

.Favourites-Container .FavChaptersList .FavChapterListItem img {
    width: 70px;
    height: 80px;
    border-radius: 8px;
}

.Favourites-Container .FavChaptersList .FavChapterListItem p {
    font: var(--button-font);
    letter-spacing: 0.005em;
    color: var(--navy);
    margin: 0;
    min-width: 227px;
}

.Favourites-Container .FavChaptersList .FavChapterListItem .heart {
    min-width: 40px;
}


/* Tablet */
@media only screen and (min-width: 768px) {
    .Favourites-Container {
        text-align: center;
        width: 768px;
        margin: 0px auto;
    }

    .Favourites-Container h4 {
        font-size: 28px;
        line-height: 34px;
        letter-spacing: 0.05em;
    }

    .Favourites-Container .Tags-List {
        width: 720px;
        margin: auto;
    }

    .Favourites-Container .Tags-List .slick-track {
        min-width: 720px;
    }

    .Favourites-Container .FavChaptersList {
        width: 720px;
    }

    .Favourites-Container .FavChaptersList .FavChapterListItem img {
        width: 100px;
        height: 110px;
        border-radius: 11px;
    }

    .Favourites-Container .FavChaptersList .FavChapterListItem p {
        min-width: 540px;
        text-align: left;
    }

}

/* Browsers */
@media only screen and (min-width: 1200px) {
    .Favourites-Container {
        width: 1228px;
    }
}