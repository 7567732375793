.ScoreCardDetails {
    text-align: center;

    height: 450px;
    width: 100%;
    overflow-y: scroll;

    padding-bottom: 28px;
}

.ScoreCardDetails h2 {
    font: var(--heading-font);
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: var(--text-regular-colour);
    margin: 0;
    padding-top: 20px;
}

.ScoreCardDetails .ScoreCardDetail {
    border-bottom: 1px solid var(--mint);
    width: 307px;
    margin: auto;
}

.ScoreCardDetails .ScoreCardDetail:last-child {
    border-bottom: 0;
}

.ScoreCardDetails .ScoreCardDetail h4 {
    font: var(--regular-font);
    color: var(--text-heading-colour);

    margin-top: 29px;
    margin-bottom: 20px;
}

.ScoreCardDetails .ScoreCardDetail p {
    text-align: left;
    font: var(----heading-font);
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.03em;
    padding-bottom: 20px;
    color: var(--text-heading-colour);
}

.ScoreCardDetails .ScoreCardDetail .HTMLScoreRenderer p:first-child{
    margin-top: 15px;
} 

.ScoreCardDetails .ScoreCardDetail .HTMLScoreRenderer p {
    margin: 8px 0px;
    padding-bottom: 0px;
}

.ScoreCardDetails .ScoreCardDetail .HTMLScoreRenderer ul {
    text-align: left;
    margin: 8px 0px;
}

/* Tablet */
@media only screen and (min-width: 768px) {

    .ScoreCardDetails .ScoreCardDetail {
        width: 600px;
    }

}