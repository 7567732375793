.heart {
    position: relative;
    width: 25px;
    height: 25px;
    background-image: url("./assets/heart.svg");
    background-repeat: no-repeat;
    cursor: pointer;
    transition-duration: 0.4s;
  }

  .heart.unset {
    background-image: url("./assets/heart-unset.svg");
  }

  .heart:hover {
    transition-duration: 0.1s;
  }
  
  .heart:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 4em;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 0 0 10px 40px black;
  }
  
  .heart:active:after {
    box-shadow: 0 0 0 0 black;
    position: absolute;
    border-radius: 4em;
    left: 0;
    top:0;
    opacity: 1;
    transition: 0s;
  }
  
  .heart:active {
    top: 1px;
  }