
.CarouselContainer {
    width: 338px;
    margin: auto;
}

.CarouselContainer h3 {
    font: var(--regular-font);
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: var(--text-heading-colour);
    text-align: left;
}

.CarouselContainer .slick-slider {
    position: relative;
}

.CarouselContainer .slick-arrow {
    display: none;
}

.CarouselContainer .slick-track {
    margin-left: inherit;
}


/* Tablet */
@media only screen and (min-width: 768px) {
    .CarouselContainer {
        width: 720px;
    }

    .CarouselContainer h3 {
        font-size: 38px;
        line-height: 36px;
    }

    .CarouselContainer .slick-arrow {
        display: block;
        width: 60px;
        height: 60px;
        border-radius: 20px;
        position: absolute;
        background-color: white;
        opacity: 1;
    }
    
    .CarouselContainer .slick-arrow .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    .CarouselContainer .slick-arrow.slick-disabled {
        display: none;
    }
    
    .CarouselContainer .slick-arrow.slick-prev {
        left: 0;
        top: 80px;
        z-index: 2;
    }
    
    .CarouselContainer .slick-arrow.slick-prev .icon {
        top: 10%;
        left: 25%;
        transform: rotate(180deg) translate(-50%, -50%);
    }
    
    .CarouselContainer .slick-arrow.slick-next {
        right: 0;
        top: 80px;
    }


}