
.player {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
}

.player .controls {
    position: absolute;
    bottom: -50px;

    width: 100%;

    display: flex;
    justify-content: space-between;
}