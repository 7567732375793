
.Profile-Container {
    text-align: left;
    padding: 0px 15px;
}

.Profile-Container h2 {
    font: var(--heading-font);
    padding-top: 29px;
}

.Profile-Container h4 {
    color: var(--text-regular-colour);
    font: var(--regular-font);
    margin-top: 44px;
}

.Profile-Container a {
    font: var(--heading-font);
    font-size: 17px;
    line-height: 22px;

    color: var(--text-regular-colour);
}

.UserName {
    margin-top: 62px;
}

.UserName.ReducedMarginTop {
    margin-top: 20px;
}

.InputField p {
    color: var(--input-label-colour);
    font: var(--input-label-font);
    letter-spacing: 0.02em;
}

.InputField input[type=text], input[type=password] {
    background: var(--white);
    border: 1px solid var(--greyshingle);
    box-sizing: border-box;
    border-radius: 4px;

    width: 100%;
    height: 59px;

    margin: 6px 0px;
    padding: 1px 5px;
}

.Terms input[type=checkbox] {
    border: 1px solid var(--greyshingle);
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 4px;

    margin: 5px 0px;
    margin-right: 3px;

    width: 18px;
    height: 18px;
}

.Terms {
    margin-top: 25px;
}

.Terms.Center {
    text-align: center;
    margin-top: 40px;
}

.InputField input[readonly] {
    background-color:var(--grey);
    outline: none;
}

.lockedInput {
    position: relative;
}

.lockedInput img {
    position: absolute;
    right: 25px;
    bottom: 25px;
}

.Profile-Container hr {
    background-color: var(--mint);
    border: none;
    height: 0.8px;
    margin-top: 25px;
}

.Profile-Container .SignIn-Container {
    margin-top: 77px;
    text-align: center;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .Profile-Container {
        text-align: center;
    }

    .Profile-Container .UserName, .Password {
        text-align: left;
        width: 337px;
        margin: 0px auto;
    }

    .Profile-Container .UserName {
        margin-top: 30px;
    }

    .Profile-Container .UserName.First {
        margin-top: 60px;
    }

    .Profile-Container .Terms {
        text-align: left;
        width: 337px;
        margin: 0px auto;
    }

    .Profile-Container .Terms.Center {
        text-align: center;
        margin-top: 40px;
    }

    .Profile-Container hr {
        width: 337px;
    }
}

/* Browsers */
@media only screen and (min-width: 1200px) {
    .Profile-Container {
        text-align: center;
        width: 1228px;
        margin: 0px auto;
    }
}