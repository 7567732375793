.InstructionsModal {
    font: var(--input-label-font);
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.03em;
}

.InstructionsModal li {
    line-height: 47px;
}

.InstructionsModal li img {
    position: relative;
    top: 10.5px;
}