
.GettingStarted {
    color: var(--text-heading-colour);
    letter-spacing: 0.05em;
}

.GettingStarted h2 {
    font: var(--heading-font);
    padding-top: 29px;
}

.GettingStarted h4 {
    color: var(--text-regular-colour);
    font: var(--regular-font);
    margin-top: 13px;
    margin-bottom: 59px;
}

.GettingStarted .ButtonGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.GettingStarted .ButtonGroup input {
    margin-bottom: 7px;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .GettingStarted .ButtonGroup {
        margin-top: 80px;
    }
}

/* Browsers */
@media only screen and (min-width: 1200px) {
    .GettingStarted h2 {
        font-size: 36px;
    }

    .GettingStarted .ButtonGroup {
        margin-top: 76px;
    }
}