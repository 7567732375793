.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    z-index: 10;
}

/* Tablet */
@media only screen and (min-width: 768px) {

    .loader {
        width: 200px;
    }

}
  
  /* Browsers */
  @media only screen and (min-width: 1200px) {
    .loader {
        width:300px;
    }
  }