
.ConfirmPassword-Container {
    text-align: left;
    padding: 0px 15px;
}

.ConfirmPassword-Container h2 {
    font: var(--heading-font);
    padding-top: 29px;
}

.ConfirmPassword-Container h4 {
    color: var(--text-regular-colour);
    font: var(--regular-font);
    margin-top: 44px;
}

.UserName {
    margin-top: 62px;
}

.InputField p {
    color: var(--input-label-colour);
    font: var(--input-label-font);
    line-height: 0.02em;
}

.InputField input[type=text], input[type=password] {
    background: var(--white);
    border: 1px solid var(--greyshingle);
    box-sizing: border-box;
    border-radius: 4px;

    width: 100%;
    height: 59px;

    margin: 6px 0px;
    padding: 1px 5px;
}

.ConfirmPassword-Container .SignIn-Container {
    margin-top: 77px;
    text-align: center;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .ConfirmPassword-Container {
        text-align: center;
    }

    .ConfirmPassword-Container .UserName, .Password {
        text-align: left;
        width: 337px;
        margin: 0px auto;
    }

    .ConfirmPassword-Container .UserName {
        margin-top: 99px;
    }
}

/* Browsers */
@media only screen and (min-width: 1200px) {
    .ConfirmPassword-Container {
        text-align: center;
        width: 1228px;
        margin: 0px auto;
    }
}