
.MultipleChoiceQuestion {
    color: var(--text-regular-colour);
}

.MultipleChoiceQuestion .Question {
    font: var(--regular-font);
    font-size: 20px;
    letter-spacing: 0.05em;
}

.MultipleChoiceQuestion .Options {
    margin-top: 33px;
    width: 337px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
}

.MultipleChoiceQuestion .Options .ImageButton {
    margin-bottom: 8px;
}