
.ProgressBar {
    position: relative;
    width: 100%;
    height: 8px;
    background-color: var(--light-grey);
}

.ProgressBar .Indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: 8px;
    background-color: var(--barney);
}

/* Tablet */
@media only screen and (min-width: 768px) {

    .ProgressBar {
        height: 12px;
    }

    .ProgressBar .Indicator {
        height: 12px;
    }
}