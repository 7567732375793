
.Stress-Container {
    text-align: left;
    padding: 0px 15px;
    margin-top: 10px;
    overflow: hidden;
}

.Stress-Container h2 {
    font: var(--heading-font);
    padding-top: 29px;
    margin-top: 0;
}

.Stress-Container h4 {
    color: var(--text-regular-colour);
    font: var(--regular-font);
    margin-top: 44px;
}

.Stress-Container h3 {
    font: var(--regular-font);
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: var(--text-heading-colour);
    text-align: left;
}

.Stress-Container .CarouselContainer {
    margin-top: 40px;
    margin-bottom: 20px;
}

.Stress-Container .CarouselContainer .slick-list {
    overflow: visible;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .Stress-Container {
        text-align: center;
        overflow: visible;
    }

    .Stress-Container h2 {
        font-size: 38px;
        line-height: 36px;
    }

    .Stress-Container h3 {
        font-size: 38px;
        line-height: 36px;
    }

    .Stress-Container .CarouselContainer .slick-list {
        overflow: hidden;
    }

    .Stress-Container .ScoreContainer {
        width: 720px;
        margin: auto;
    }

}

/* Browsers */
@media only screen and (min-width: 1200px) {
    .Stress-Container {
        text-align: center;
        width: 1228px;
        margin: 0px auto;
    }
}