
.CarePathway-Container {
    text-align: left;
    padding: 0px 15px;
}

.CarePathway-Container .titles {
    z-index: 2;
    position: relative;
}

.CarePathway-Container h2 {
    font: var(--heading-font);
    padding-top: 29px;
    margin-bottom: 0;
}

.CarePathway-Container h4 {
    color: var(--text-regular-colour);
    font: var(--regular-font);
    margin-top: 10px;
}

.CarePathway-Container .SignIn-Container {
    margin-top: 77px;
    text-align: center;
}

.CarePathway-Container .list-options {
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    width: 337px;
    justify-content: space-between;
    row-gap: 11px;
    margin: 0 auto;
}

.CarePathway-Container .Background {
    background-image: url("./assets/paths.png");
    background-size: cover;

    width: 100%;
    height: 456px;

    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .CarePathway-Container {
        text-align: center;
    }

    .CarePathway-Container .list-options {
        width: 338px;
    }

    .CarePathway-Container .Background {
        height: 860px;
    }
}

/* Browsers */
@media only screen and (min-width: 1200px) {
    .CarePathway-Container {
        text-align: center;
        width: 1228px;
        margin: 0px auto;
    }

    .CarePathway-Container .Background {
        height: 1060px;
    }
}