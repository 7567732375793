.ScoreCard {
    width: 338px;
    height: 151px;
    margin: auto;
    border-radius: 13px;
    margin-bottom: 10px;

    background-color: #cec6f6;
    background-image: url(./assets/head.png);
    background-repeat: no-repeat;
    background-position: right bottom;

    font: var(--input-label-font);
    color: var(--navy);
}

.ScoreCard h6 {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    padding-left: 16px;
    padding-top: 16px;
}

.ScoreCard .Score {
    margin: 20px 0;
    padding-left: 16px;
}

.ScoreCard .Score .value {
    min-width: 25px;

    font-weight: 400;
    font-size: 28px;
    line-height: 28px;

    display: inline-block;
    margin: 0;
}

.ScoreCard .Score .label {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;

    display: inline-block;
    margin: 0;
    margin-left: 15px;

    max-width: 280px;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .ScoreCard {
        text-align: left;
        width: 720px;
    }
}