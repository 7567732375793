.FeaturedContainer {
    width: 337px;
    margin: auto;

    position: relative;
}

.FeaturedSession {
    display: flex;
    flex-direction: row;
    padding-bottom: 12px;
}

.FeaturedSession .SessionArtwork {
    width: 128px;
    height: 161px;
    background-size: contain;
    border-radius: 20px;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.12));
}

.FeaturedSession .Details {
    width: 192px;
    height: 162px;
    padding-left: 20px;
}

.FeaturedSession .Details h4 {
    font: var(--button-font);
    color: var(--text-regular-colour);
    letter-spacing: 0.05em;
    margin: 0;
    margin-top: 5px;
}

.FeaturedSession .Details p {
    color: var(--text-heading-colour);
    font: var(--input-label-font);
    letter-spacing: 0.02em;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .FeaturedContainer {
        width: 722px;
    }

    .FeaturedSession .SessionArtwork {
        width: 256px;
        height: 322px;
    }

    .FeaturedSession .Details {
        width: 437px;
        height: 322px;

        text-align: left;
    }

    .FeaturedSession .Details h4 {
        font: var(--heading-font);
        font-size: 32px;
        line-height: 32px;
    }

    .FeaturedSession .Details p {
        font-size: 17px;
        line-height: 22px;
        letter-spacing: 0.03em;
    }

    .FeaturedContainer .ActionContainer {
        width: 437px;
        position: absolute;
        right: 0;
        bottom: 15px;
    }
}