.IS-Button {
    background-color: var(--white);

    border: 1px solid var(--black);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;

    font: var(--button-font);
    letter-spacing: 0.05em;

    width: 337px;
    height: 57px;

    color: var(--black);
}

.IS-Button:disabled{
    opacity: 0.4;
}

.IS-Button.filled {
    background-color: var(--primary-colour);
}

.IS-Button.filled:hover:enabled {
    border: 2px solid var(--black);
}

.IS-Button.clear {
    background-color: var(--secondary-colour);
}

.IS-Button.clear:hover:enabled {
    border: 2px solid var(--barney);
}