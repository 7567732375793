
/* Variables defintions for all colors and fonts used within the InwardStrong system */
:root {
    /* COLOURS */
    --black: black;
    --white: #ffffff;
    --offwhite: #FFFDFD;
    --turquoise: #09495F;
    --cornbread: #FFEEA4;
    --pumpkin: #FAAC45;
    --barney: #9B5DE5;
    --greyshingle: #949494;
    --shark: #222225;
    --grey: #EBEAEA;
    --navy: #173753;
    --mint: #BEE5BF;
    --blue: #007AFF;
    --light-grey:  #E5E4E4;

    --primary-colour: var(--pumpkin);
    --secondary-colour: var(--offwhite);
    --territary-colour: var(--cornbread);

    --text-heading-colour: var(--black);
    --text-regular-colour: var(--turquoise);
    --input-label-colour: var(--shark);

    /* FONTS */
    --font-family: Proxima;
    --heading-font: normal normal 600 28px/36px Proxima;
    --regular-font: normal normal normal 28px/34px Proxima;
    --button-font: normal normal 800 19px/23px Proxima;
    --input-label-font: normal normal normal 15px/16px Proxima;
  }