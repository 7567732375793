
.Tools-Container {
    text-align: left;
    padding: 0px 15px;
}

.Tools-Container h2 {
    font: var(--heading-font);
    padding-top: 29px;
}

.Tools-Container h4 {
    color: var(--text-regular-colour);
    font: var(--regular-font);
    margin-top: 44px;
}

.Tools-Container .Tools {
    position: relative;
}

.Tools-Container .Tags-List {
    text-align: left;
    width: 339px;
    margin: 0 auto;

    position: sticky;
}

.Tools-Container .Tools-List {
    display: flex;
    flex-direction: column;
    row-gap: 13px;

    width: 339px;
    /* height: 720px; */
    height: 65vh;
    overflow-y: scroll;

    margin: 0 auto;
    text-align: left;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
}

.Tools-Container .Tools-List::-webkit-scrollbar {
    display: none;
}


/* Tablet */
@media only screen and (min-width: 768px) {
    .Tools-Container {
        text-align: center;
    }

    .Tools-Container h2 {
        padding-top: 5px;
    }

    .Tools-Container .Tools-List {
        width: 709px;
        /* height: 90vh; */
        height: 73vh;
        padding-top: 25px; 
    }

    .Tools-Container .Tags-List {
        width: 709px;
    }
}

/* Browsers */
@media only screen and (min-width: 1200px) {
    .Tools-Container {
        text-align: center;
        width: 1228px;
        margin: 0px auto;
    }

    .Tools-Container .Tools-List {
        height: 78vh;
    }
}