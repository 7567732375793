
.messageContainer {
    text-align: center;
    margin: 0 11px;
    padding-top: 61px;
    padding-bottom: 15px;

    font: var(--input-label-font);
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.03em;
}

.messageContainer .heading{
    font: var(--button-font);
    letter-spacing: 0.05em;
}

.messageContainer .message {
    text-align: left;
    margin-bottom: 64px;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .messageContainer .icon {
        width: 83px;
        height: 83px;
    }

    .messageContainer .heading {
        font-size: 38px;
        line-height: 36px;
    }

    .messageContainer .message {
        text-align: center;
        margin-bottom: 100px;
    }
}


/* Browser */
@media only screen and (min-width: 1200px) {
    .messageContainer .message {
        margin-bottom: 140px;
    }
}