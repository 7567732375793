
.Login-Container {
    text-align: left;
    padding: 0px 15px;
}

.Login-Container h2 {
    font: var(--heading-font);
    padding-top: 29px;
}

.Login-Container h4 {
    color: var(--text-regular-colour);
    font: var(--regular-font);
    margin-top: 44px;
}

.Login-Container a {
    font: var(--heading-font);
    font-size: 17px;
    line-height: 22px;

    color: var(--text-regular-colour);
}

.Login-Container .SignIn-Container {
    margin-top: 30px;
    text-align: center;
}

.UserName {
    margin-top: 40px;
}

.InputField, .AccountTypes p {
    color: var(--input-label-colour);
    font: var(--input-label-font);
    line-height: 0.02em;
}

.InputField input[type=text], input[type=password] {
    background: var(--white);
    border: 1px solid var(--greyshingle);
    box-sizing: border-box;
    border-radius: 4px;

    width: 100%;
    height: 59px;

    margin: 6px 0px;
    padding: 1px 5px;
}

.Login-Container .Terms {
    text-align: right;
    padding-right: 6px;
}

.AccountTypes {
    text-align: center;
    width: 337px;
    margin: 0 auto;
    margin-top: 40px;
}

.AccountTypes .Google {
    margin: 15px 0px;
}

.AccountTypes .Facebook {
    margin-bottom: 100px;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .Login-Container {
        text-align: center;
    }

    .Login-Container .SignIn-Container, .Terms {
        width: 337px;
        margin: 0 auto;
    }

    .Login-Container .SignIn-Container {
        width: 337px;
        margin-top: 64px;
    }

    .Login-Container .AccountTypes {
        width: 337px;
        margin: 64px auto;
    }

    .Login-Container .UserName {
        text-align: left;
        width: 337px;
        margin: 0px auto;
        margin-top: 99px;
    }
}

/* Browsers */
@media only screen and (min-width: 1200px) {
    .Login-Container {
        text-align: center;
        width: 1228px;
        margin: 0px auto;
    }
}