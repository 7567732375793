
.Home-Container {
    text-align: left;
    padding: 0px 15px;
    margin-top: 10px;
    overflow: hidden;
}

.Home-Container h2 {
    font: var(--heading-font);
    padding-top: 39px;
    margin-top: 0;
}

.Home-Container h4 {
    color: var(--text-regular-colour);
    font: var(--regular-font);
    margin-top: 44px;
}

.Home-Container .list-options {
    display: flex;
    flex-wrap: wrap;
    width: 337px;
    justify-content: space-between;
    row-gap: 11px;
    margin: 28px auto;
}

.Home-Container .CarouselContainer .slick-list {
    overflow: visible;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .Home-Container {
        text-align: center;
        overflow: visible;
    }

    .Home-Container .list-options {
        width: 720px;
    }

    .Home-Container .CarouselContainer .slick-list {
        overflow: hidden;
    }
}

/* Browsers */
@media only screen and (min-width: 1200px) {
    .Home-Container {
        text-align: center;
        width: 1228px;
        margin: 0px auto;
    }
}