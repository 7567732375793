
.SignUp-Container {
    text-align: left;
    padding: 0px 15px;
}

.SignUp-Container h2 {
    font: var(--heading-font);
    padding-top: 29px;
}

.SignUp-Container h4 {
    color: var(--text-regular-colour);
    font: var(--regular-font);
    margin-top: 44px;
}

.SignUp-Container a {
    font: var(--heading-font);
    font-size: 17px;
    line-height: 22px;

    color: var(--text-regular-colour);
}

.UserName {
    margin-top: 62px;
}

.InputField, .AccountTypes p {
    color: var(--input-label-colour);
    font: var(--input-label-font);
    line-height: 0.02em;
}

.InputField input[type=text] {
    background: var(--white);
    border: 1px solid var(--greyshingle);
    box-sizing: border-box;
    border-radius: 4px;

    width: 100%;
    height: 59px;

    margin: 6px 0px;
    padding: 1px 5px;
}

.Terms input[type=checkbox] {
    border: 1px solid var(--greyshingle);
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 4px;

    margin: 5px 0px;
    margin-right: 3px;

    width: 18px;
    height: 18px;
}

.AccountTypes {
    text-align: center;
    width: 337px;
    margin: 0 auto;
    margin-top: 64px;
}

.AccountTypes .Google {
    margin: 21px 0px;
}

.AccountTypes .Facebook {
    margin-bottom: 100px;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .SignUp-Container {
        text-align: center;
    }

    .SignUp-Container .AccountTypes {
        width: 337px;
        margin: 64px auto;
    }

    .SignUp-Container .UserName {
        text-align: left;
        width: 337px;
        margin: 0px auto;
        margin-top: 99px;
    }

    .SignUp-Container .Terms {
        text-align: left;
        width: 337px;
        margin: 0px auto;
    }
}

/* Browsers */
@media only screen and (min-width: 1200px) {
    .SignUp-Container {
        text-align: center;
        width: 1228px;
        margin: 0px auto;
    }
}