
@font-face { font-family: Proxima; src: url('./shared/assets/ProximaNovaRegular.otf'); } 

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type=button], input[type=text], input[type=password] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=text], input[type=password] {
  font: var(--input-label-font);
  font-size: 18px;
}