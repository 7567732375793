
.LinkButton {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;

    border: 0;
    background: none;

    cursor: pointer;
}

.LinkButton p{
    color: var(--text-regular-colour);
    font: var(--input-label-font);
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.03em;
    margin: 0;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .LinkButton p { 
        font-size: 19px;
    }
}