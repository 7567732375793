.Mindful-Minute-Container {
    position: relative;
    margin-top: 40px;
    width: 100%;
}

.Mindful-Minute-Container iframe {
    border: none;
    width: 100%;
    height: 84vh;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .Mindful-Minute-Container iframe {
        height: 90vh;
    }
}

/* Browsers */
@media only screen and (min-width: 1200px) {
    .Mindful-Minute-Container iframe {
        height: 90vh;
    }
}