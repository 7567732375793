.MoodTracker-Container {
    text-align: left;
    padding: 0px 15px;
    margin-top: 10px;
}

.MoodTracker-Container h2 {
    font: var(--heading-font);
    padding-top: 29px;
    margin-top: 0;
}

.MoodTracker-Container h4 {
    font: var(--regular-font);
    color: var(--text-regular-colour);
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.03em;
    margin-top: 0;
}

.MoodTracker-Container .Assessments {
    margin: 20px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.MoodTracker-Container .ScoreContainer h3 {
    font: var(--regular-font);
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: var(--text-heading-colour);
    text-align: left;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .MoodTracker-Container {
        text-align: center;
        width: 768px;
        margin: 0px auto;
    }

    .MoodTracker-Container h4 {
        font-size: 28px;
        line-height: 34px;
        letter-spacing: 0.05em;
    }

    .MoodTracker-Container .Assessments {
        flex-direction: row;
    }

    .MoodTracker-Container .ScoreContainer {
        width: 720px;
        margin: auto;
    }

}

/* Browsers */
@media only screen and (min-width: 1200px) {
    .MoodTracker-Container {
        width: 1228px;
    }
}