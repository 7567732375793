
.TokensContainer {
    text-align: left;
    padding: 0px 15px;
    margin-top: 10px;
}

.TokensContainer .Collective {
    margin-top: 40px;
}

.TokensContainer h2 {
    font: var(--heading-font);
    padding-top: 29px;
    margin-top: 0;
}

.TokensContainer h4 {
    color: var(--text-heading-colour);
    font: var(--heading-font);
    font-size: 28px;
    line-height: 34px;
    font-weight: 400;
    margin: 4px 0;
}

.TokensContainer .Content p {
    color: var(--text-heading-colour);
    font: var(--button-font);
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    margin-top: 4px;
    margin-bottom: 20px;
}

.TokensContainer li {
    padding-bottom: 10px;
}

.TokensContainer .TokensProgressBar {
    margin: 25px auto;
}

.TokensContainer .TokensProgressBar .InsideCard {
    text-align: center;
}

.TokensContainer .TokensProgressBar .InsideCard h4 {
    font: var(--heading-font);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #4E4E53;
}

.TokensContainer .TokensProgressBar .InsideCard .MainHeading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 3px;
}

.TokensContainer .TokensProgressBar .InsideCard .MainHeading h3 {
    font: var(--heading-font);
    font-size: 34px;
    line-height: 41px;
    margin: 0;
}

.TokensContainer .TokensProgressBar .InsideCard .MainHeading p {
    font: var(--heading-font);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--text-heading-colour);

    position: relative;
    bottom: -12px;
}

.TokensContainer .TokensProgressBar .InsideCard p {
    font: var(--heading-font);
    font-size: 17px;
    line-height: 22px;
}

.TokensContainer .TokensProgressBar .InsideCard.Blue .blurb {
    color: #007AFF;
}

.TokensContainer .TokensProgressBar .InsideCard.Pumpkin .blurb {
    color: #FAAC45;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .TokensContainer {
        text-align: center;
        overflow: visible;
    }

    .TokensContainer h2 {
        padding-top: 5px;
    }

    .TokensContainer h4 {
        margin-bottom: 15px;
    }

}

/* Browsers */
@media only screen and (min-width: 1200px) {
    .TokensContainer {
        text-align: center;
        width: 1228px;
        margin: 0px auto;
    }
}