.logo {
    width: 100px;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .logo {
        width: 150px;
        object-fit: contain;
    }
}