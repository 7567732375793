
.Session-Container {
    text-align: left;
    padding: 0px 15px;

    margin: 0 auto;
    margin-top: 29px;

    overflow: hidden;
}

.Session-Container .Chapter-Details {
    width: 338px;
    margin: 0 auto;
}

.Session-Container h2 {
    font: var(--heading-font);
    padding-top: 29px;
    margin: 0;
}

.Session-Container h4 {
    color: var(--text-regular-colour);
    font: var(--regular-font);
    font-size: 19px;
    line-height: 26px;
    font-weight: 600;
    letter-spacing: 0.05em;
    margin-top: 24px;
}

.Session-Container .video-container {
    position: relative;
    width: 100%;
    height: 229px;
    margin: 16px auto;
}

.Session-Container .details {
    font: var(-input-label-font);
    font-size: 17px;
    line-height: 22px;
    margin: 0 auto;
}

.Session-Container .actionBtns {
    margin: 0 auto;
}

.Session-Container .CarouselContainer .slick-list {
    overflow: visible;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .Session-Container {
        text-align: center;

        width: auto;
        overflow: visible;
    }

    .Session-Container .Chapter-Details {
        width: auto;
        margin: auto;
    }

    .Session-Container h2 {
        font-size: 36px;
    }

    .Session-Container .video-container {
        height: 405px;
    }

    .Session-Container .details, .Session-Container .actionBtns { 
        text-align: left;
        width: 720px;
    }

    .Session-Container .CarouselContainer .slick-list {
        overflow: hidden;
    }
}

/* Browsers */
@media only screen and (min-width: 1200px) {
    .Session-Container {
        text-align: center;
        width: 1228px;
        margin: 0px auto;
    }
}