
.Footer {
    position: absolute;
    bottom: 0;
    padding: 5px 0;
    width: 100%;

    display: flex;
    column-gap: 5px;
    justify-content: center;
}

.Footer h3 {
    font: var(--heading-font);
    font-size: 12px;
    line-height: 16px;

    text-decoration: none;

    margin: 0;
}

/* Tablet */
@media only screen and (min-width: 768px) {

    .Footer h3 {
        font-size: 14px;
    }

}