
.ForgotPassword-Container {
    text-align: left;
    padding: 0px 15px;
}

.ForgotPassword-Container h2 {
    font: var(--heading-font);
    padding-top: 29px;
}

.ForgotPassword-Container h4 {
    color: var(--text-regular-colour);
    font: var(--regular-font);
    margin-top: 44px;
}

.ForgotPassword-Container .SignIn-Container {
    margin-top: 30px;
    text-align: center;
}

.UserName {
    margin-top: 40px;
}

.InputField, .AccountTypes p {
    color: var(--input-label-colour);
    font: var(--input-label-font);
    line-height: 0.02em;
}

.InputField input[type=text] {
    background: var(--white);
    border: 1px solid var(--greyshingle);
    box-sizing: border-box;
    border-radius: 4px;

    width: 100%;
    height: 59px;

    margin: 6px 0px;
    padding: 1px 5px;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .ForgotPassword-Container {
        text-align: center;
    }

    .ForgotPassword-Container .SignIn-Container {
        width: 337px;
        margin: 0 auto;
        margin-top: 64px;
    }

    .ForgotPassword-Container .SignIn-Container {
        margin-top: 64px;
    }

    .ForgotPassword-Container .UserName {
        text-align: left;
        width: 337px;
        margin: 0px auto;
        margin-top: 99px;
    }
}

/* Browsers */
@media only screen and (min-width: 1200px) {
    .ForgotPassword-Container {
        text-align: center;
        width: 1228px;
        margin: 0px auto;
    }
}