.Tool {
    display: flex;
    /* column-gap: 20px; */
    cursor: pointer;
    min-height: 180px;
}

.Tool .artwork {
    border-radius: 20px;
    width: 128px;
    height: 161px;
}

.Tool .metadata {
    width: 191px;
    margin-left: 20px;
}

.Tool .metadata .title {
    font: var(--button-font);
    color: var(--text-regular-colour);
    letter-spacing: 0.05em;
    margin-top: 0px;
}

.Tool .metadata .description {
    font: var(--input-label-font);
    color: var(--text-heading-color);
    letter-spacing: 0.03em;
}

/* Tablet */
@media only screen and (min-width: 768px) {

    .Tool {
        /* column-gap: 33px; */
        min-height: 240px;
    }

    .Tool .artwork {
        width: 170px;
        height: 220px;
    }

    .Tool .metadata {
        width: 506px;
        margin-left: 33px;
    }

    .Tool .metadata .title {
        font-size: 32px;
        line-height: 32px;
    }
    
    .Tool .metadata .description {
        font-size: 17px;
        line-height: 22px;
    }
}

/* Browsers */
@media only screen and (min-width: 1200px) {
    
}