
.Settings-Container {
    text-align: left;
    padding: 0px 15px;
    margin-top: 10px;
    overflow: hidden;
}

.Settings-Container h2 {
    font: var(--heading-font);
    padding-top: 29px;
    margin-top: 0;
}

.Settings-Container h3 {
    font: var(--regular-font);
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.05em;
}

.Settings-Container .Profile-Details {
    width: 338px;
    margin: 0 auto;
    border-bottom: 1px solid #E6E4EA;
    cursor: pointer;
}

.Settings-Container .Profile-Details h4 {
    color: var(--text-heading-colour);
    font: var(--button-font);
    letter-spacing: 0.05em;
    margin-top: 44px;
    margin-bottom: 0;
}

.Settings-Container .Profile-Details img {
    position: relative;
    float: right;
    top: -50px;
    right: 25px;
    width: 10px;
}

.Settings-Container .Profile-Details p {
    color: var(--barney);
    font: var(--heading-font);

    font-size: 17px;
    line-height: 22px;

    margin-top: 0;
    text-decoration: underline;
}

.Settings-Container .Settings-Details{
    width: 338px;
    margin: auto;
    border-bottom: 1px solid #E6E4EA;
    cursor: pointer;
}

.Settings-Container .Settings-Details .details {
    display: inline-block;
    position: relative;
    width: 260px;
    margin-left: 8px;
    top: -5px;
}

.Settings-Container .Settings-Details .details p {
    margin: 0;
    margin-bottom: 4px;
    font: var(--input-label-font);
    letter-spacing: 0.02em;
}

.Settings-Container .Settings-Details .details .blue {
    color: var(--blue);
}

.Settings-Container .Settings-Details .details img {
    float: right;
    width: 10px;
    position: relative;
    top: -33px;
    right: -4px;
}

.Settings-Container .Settings-Details .icon {
    position: relative;
    display: inline-block;
}

.Settings-Container .Settings-Details .icon .bg {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: var(--blue);
    opacity: 0.4;
}


.Settings-Container .Settings-Details .icon img {
    position: absolute;
    top: 9px;
    left: 9px;
}

.Settings-Container .Settings-Details.second,.Settings-Container .Settings-Details.third {
    margin-top: 30px;
}

.Settings-Container .Settings-Details.third .icon img {
    width: 22px;
    height: 22px;
}

.Settings-Container .Button-Container {
    margin-top: 77px;
    text-align: center;
}

.Settings-Container .logout {
    margin: auto;
}


.Settings-Container .logout p {
    color: var(--blue);
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .Settings-Container {
        text-align: center;
        overflow: visible;
    }

    .Settings-Container h3 {
        text-align: left;
        width: 720px;
        margin: 40px auto;
    }

    .Settings-Container .Profile-Details,.Settings-Container .Settings-Details {
        text-align: left;
        width: 720px;
        margin: 0 auto;
    }

    .Settings-Container .Settings-Details .details {
        width: 642px;
    }

    .Settings-Container .Profile-Details h4 {
        font-size: 32px;
        line-height: 32px;
    }

    .Settings-Container .Profile-Details p {
        font-size: 20px;
        line-height: 22px;
    }
}

/* Browsers */
@media only screen and (min-width: 1200px) {
    .Settings-Container {
        text-align: center;
        width: 1228px;
        margin: 0px auto;
    }
}