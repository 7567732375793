.NoteCard {
    width: 100%;
    min-height: 46px;
    background-color: antiquewhite;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 5px;
    margin: 20px auto;
    transition: opacity 2s;
    opacity: 1;
    cursor: pointer;
}

.NoteCard:active {
    transform: scale(0.98);
    box-shadow: 0px 2px 3px rgb(0 0 0 / 25%);
}

.NoteCard h4 {
    margin: 0;
    padding: 10px 5px;
}

.NoteCard .Contents {
    padding: 10px 5px;
}

.NoteCard .Contents p {
    margin: 0;
    display: inline-block;
    color: var(--text-heading-colour);
    font: var(--regular-font);
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.05em;
}

.NoteCard .Contents .highlight {
    margin: 0;
    display: inline-block;
    color: var(--text-regular-colour);
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.05em;
}

/* Tablet */
@media only screen and (min-width: 768px) {

    .NoteCard {
        width: 720px;
        text-align: left;
    }

    .NoteCard h4 {
        padding: 10px 10px;
    }
    
    .NoteCard .Contents {
        padding: 10px 10px;
    }

}