.Article-Container{
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: -60px;
    min-width: 375px;
}

.Article-Container iframe {
    min-width: 100%;
    min-height: 85vh;
    overflow: hidden;
}

/* Tablet */
@media only screen and (min-width: 768px) {

    .Article-Container{
        margin-top: 50px;
        max-width: 100%;
        margin-bottom: 0px;
    }

    .Article-Container iframe {
        min-width: 100%;
        height: 85vh;
    }
}

/* Browsers */
@media only screen and (min-width: 1200px) {

    .Article-Container {
        position: absolute;
        top: 10px;
        width: 100vw;
        height: calc(100vh - 120px);
    }

    .Article-Container iframe {
        min-width: 100%;
        height: calc(100vh - 120px);
    }

}