
.chip {
    position: relative;
    display: inline-block;
    padding: 7px 10px;
    margin-right: 11px;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--secondary-colour);

    border-radius: 20px;
    text-align: center;
    vertical-align: middle;

    font: var(--input-label-font);
    font-size: 17px;
    line-height: 22px;

    color: var(--barney)
}

.chip p {
    margin: 0;
    display: inline-block;
    position: relative;
}

.chip.selected p {
    bottom: 2px;
}

.chip.selected .icon {
    display: inline-block;
    position: relative;
    top: 3px;
    left: 2px;
    width: 22px;
    height: 22px;
}

.chip.selected {
    background-color: var(--barney);
    color: var(--secondary-colour)
}