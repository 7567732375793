.ImageButton {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;

    width: 337px;
    height: 50px;

    background: var(--secondary-colour);
    border: 1px solid var(--black);
    box-sizing: border-box;
    border-radius: 23px;

    color: var(--black);
    font: var(--button-font);
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.03em;
}

.ImageButton.pill {
    justify-content: left;
    border: 2px solid var(--mint);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
    column-gap: 20px;
    padding-left: 30px;
}

.ImageButton.square {
    width: 163px;
    height: 140px;
    flex-direction: column;
    border: 1px solid var(--mint);
    box-sizing: border-box;
    border-radius: 20px;
    padding-top: 20px;
    justify-content: flex-start;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.12));
}

.ImageButton.square.decreasedPadding {
    padding-top: 10px;
}

.ImageButton:disabled{
    opacity: 0.7;
}

.ImageButton.Google {
    border: 1px solid #C6C7CA;
}

.ImageButton.Google p {
    color: var(--black)
}

.ImageButton.Facebook {
    background-color: #365A95;
    border: 1px solid #C6C7CA;
}

.ImageButton.Facebook p {
    color: var(--white);
}

.ImageButton.pressed {
    border: 3px solid var(--barney);
}