
.link {
    position: relative;
    display: block;
    text-decoration: none;
    text-align: left;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.12));
}

.Card{
    display: inline-block;
    position: relative;

    width: 130px;
    height: 140px;

    border-radius: 20px;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.12));
    z-index: 1;
}

.Card.locked {
    background-color: var(--secondary-colour);
    opacity: 0.6;
}

.Card .background {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.lockedIcon {
    position: absolute;
    top: 11px;
    left: 11px;
    z-index: 3;
}

.Card .flagText {
    position: absolute;
    background-color: var(--barney);
    color: var(--secondary-colour);

    top: 13px;
    left: 10px;
    border-radius: 20px;
    padding: 5px 10px;

    font: var(--input-label-font);
    letter-spacing: 0.02em;
}

.Card .flagText p {
    margin: 0;
}

.link .title {
    width: 130px;
    color: var(--text-heading-colour);
    text-align: center;
}

.link .title p {
    font: var(--input-label-font);
    margin: 0;
    margin-top: 8px;
}

/* Tablet */
@media only screen and (min-width: 768px) {

    .Card {
        width: 150px;
        height: 160px;
    }

    .link .title {
        width: 150px;
    }

}