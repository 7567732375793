
.AssessmentCard {
    width: 341px;
    height: 150px;
    padding-top: 14px;
    margin-bottom: 20px;

    background-color: var(--secondary-colour);
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.12));
    border-radius: 25px;

    text-align: center;
}

.AssessmentCard h3 {
    font: var(--button-font);
    color: var(--text-heading-colour);
    letter-spacing: 0.05em;
    margin-top: 0;
    margin-bottom: 36px; 
}

.AssessmentCard .IS-Button {
    width: 317px !important;
}

/* Tablet */
@media only screen and (min-width: 768px) {

    .AssessmentCard {
        margin-bottom: 0;
        margin-right: 20px;
    }
}