
.InstallPrompt-Box {
    position: fixed;
    bottom: 0;

    z-index: 5;

    width: 100%;
    height: 60px;

    background-color: var(--territary-colour);
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.12);
}

.InstallPrompt-Box.top {
    position: relative;
    top: 0;
    margin-bottom: -20px;
}

.InstallPrompt-Box .contents {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 16px;

    height: 100%;
}

.InstallPrompt-Box .contents p {
    font: var(--heading-font);
    color: var(--text-heading-colour);
    font-size: 15px;
    line-height: 16px;
}

.InstallPrompt-Box .contents .iOS {
    padding-top: 10px;
    cursor: pointer;
}

.InstallPrompt-Box .closeIcon {
    float: right;
}

.InstallPrompt-Box .closeIcon:after {
    content: "X";
    font-size: 12px; 
    font-weight: 600;
    margin-right: 5px;
    color: var(--primary-colour);
    text-align: center;
}

@media all and (display-mode: standalone) {
    .InstallPrompt-Box {
      display: none;
    }
  }