
.Welcome-Container {
    text-align: left;
    padding: 0px 15px;
}

.Welcome-Container h2 {
    font: var(--heading-font);
    padding-top: 29px;
    margin-bottom: 0;
}

.Welcome-Container h4 {
    color: var(--text-regular-colour);
    font: var(--regular-font);
    margin-top: 10px;
}

.Welcome-Container .SignIn-Container {
    margin-top: 77px;
    text-align: center;
}

.Welcome-Container .list-goals {
    display: flex;
    flex-wrap: wrap;
    width: 337px;
    justify-content: space-between;
    row-gap: 11px;
    margin: 0 auto;
}

.Welcome-Container .video-container {
    position: relative;
    width: 100%;
    height: 289px;
    margin: 0 auto;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .Welcome-Container {
        text-align: center;
    }

    .Welcome-Container .list-goals {
        width: 686px;
    }

    .Welcome-Container .video-container {
        width: 460px;
        height: 307px;
        margin: 0 auto;
    }
}

/* Browsers */
@media only screen and (min-width: 1200px) {
    .Welcome-Container {
        text-align: center;
        width: 1228px;
        margin: 0px auto;
    }
}