.FilterChips h3 {
    margin: 0;
    margin-bottom: 14px;
    color: var(--text-regular-colour);
    font: var(--input-label-font);
    font-size: 13px;
    line-height: 18px;
}

.FilterChips .slick-track {
    height: 60px;
    min-width: 338px;
}

.FilterChips .slick-arrow {
    display: none !important;
}

/* Tablet */
@media only screen and (min-width: 1200px) {
    .FilterChips .slick-arrow::before {
        content: "";
    }

    .FilterChips .slick-prev, .FilterChips .slick-next {
        top: 35%;
    }

    .FilterChips .slick-arrow {
        display: block !important;
        width: 60px;
        height: 60px;
        border-radius: 20px;
        position: absolute;
        opacity: 1;
    }
    
    .FilterChips .slick-arrow .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    .FilterChips .slick-arrow.slick-disabled {
        display: none !important;
    }
    
    .FilterChips .slick-arrow.slick-prev {
        left: -50px;
        z-index: 2;
    }
    
    .FilterChips .slick-arrow.slick-prev .icon {
        top: 10%;
        left: 25%;
        transform: rotate(180deg) translate(-50%, -50%);
    }
    
    .FilterChips .slick-arrow.slick-next {
        right: -60px;
    }


}