
.PopUPModal {
    position: fixed;

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 8;
}

.PopUPModal .background {
    position: absolute;

    background: var(--black);
    opacity: 0.4;

    width: inherit;
    height: inherit;
}

.PopUPModal .modalContainer {
    position: relative;
    top: 84px;
    margin: 0 auto;

    width: 339px;
    min-height: 337px;

    background: var(--secondary-colour);
    border-radius: 12px;

    text-align: center;
}

.PopUPModal .modalContainer  .closeIcon {
    float: right;
    margin-right: 8px;
    margin-top: 8px;

    width: 28px;
    height: 28px;
    background-image: url(./assets/x.jpg);

    cursor: pointer;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .PopUPModal .modalContainer {
        width: 600px;
        min-height: 479px;
    }
}


/* Browser */
@media only screen and (min-width: 1200px) {
    .PopUPModal .modalContainer {
        width: 900px;
        min-height: 520px;
    }
}