.StressLogScale {
    width: 338px;
    margin: auto;
}

.StressLogScale h2 {
    font: var(--heading-font);
    padding-top: 29px;
    margin-top: 0;
}

.StressLogScale .ScaleDetails{
    text-align: center;
}

.StressLogScale .ScaleDetails h4 {
    font: var(--button-font);
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.05em;
    margin: 0;
    margin-top: 20px;
}

.StressLogScale .ScaleDetails h4.High{
    color: #EF1320;
}

.StressLogScale .ScaleDetails h4.Medium {
    color: #FFBD00;
}

.StressLogScale .ScaleDetails h4.Low {
    color: #00D641;
}

.StressLogScale .ScaleDetails.coping h4.High{
    color:#00D641;
}

.StressLogScale .ScaleDetails.coping h4.Medium {
    color: #FFBD00;
}

.StressLogScale .ScaleDetails.coping h4.Low {
    color: #EF1320;
}

.StressLogScale .ScaleDetails p { 
    text-align: left;
    height: 90px;
    font: var(--input-label-font);
    letter-spacing: 0.02em;
}

.StressLogScale .ScaleValue {
    width: 338px;
    text-align: center;
    color: var(--barney);
    font: var(--heading-font);
}

.StressLogScale .ScaleRange {
    margin-top: 15px;
    margin-bottom: 30px;

    display: flex;
}

.StressLogScale .ScaleRange .value {
    margin: 0 6px;
    position: relative;
    bottom: 6px;

    font: var(--heading-font);
    font-size: 17px;
    line-height: 22px;
}

.StressLogScale .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    border-radius: 5px;  
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}
  
.StressLogScale .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 28px;
    height: 28px;
    border-radius: 50%; 
    background: #fff;
    box-shadow: 0px 2px 2px rgba(50, 50, 71, 0.06), 0px 2px 4px rgba(50, 50, 71, 0.06);
    border: 1px solid #9B5DE5;
    cursor: pointer;
}
  
.StressLogScale .slider::-moz-range-thumb {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 2px 2px rgba(50, 50, 71, 0.06), 0px 2px 4px rgba(50, 50, 71, 0.06);
    border: 1px solid #9B5DE5;
    cursor: pointer;
}

/* Tablet */
@media only screen and (min-width: 768px) {

    .StressLogScale, .StressLogScale .ScaleValue {
        width: 500px;
    }

    .StressLogScale h2 {
        font-size: 36px;
        line-height: 34px;
    }
}

/* Browsers */
@media only screen and (min-width: 1200px) {

}