
.App {
  position: absolute;
  top:0;
  left:0;
  right:0;
  min-height: 100vh;

  color: var(--text-heading-colour);
  font-family: var(--font-family);
}

.App-header {
  position: relative;
  top: 28px;
  height: 50px;
  text-align: center;
  z-index: 2;
}

.App-header.left {
  text-align: left;
  padding-left: 15px;
}

.App-header .Back-btn {
  position: absolute;
  display: inline-flex;
  left: 10px;
  top: 13px;
}

.App-header .Action-btn img, .App-header .Secondary-Action-btn img, .App-header .Teritiary-Action-btn img {
  width: 25px;
}

.App-header .Action-btn {
  position: absolute;
  display: inline-flex;
  right: 10px;
  top: 11px;
}

.App-header .Secondary-Action-btn {
  position: absolute;
  display: inline-flex;
  right: 50px;
  top: 11px;
}

.App-header .Teritiary-Action-btn {
  position: absolute;
  display: inline-flex;
  right: 95px;
  top: 11px;
}

.App-header .logo {
  position: relative;
  top: 5px;
}

.App-container {
  margin-bottom: 40px;
}

.App-splash, .App-container {
  text-align: center;
  position: relative;
}

.App-splash {
  padding-top: 80px;
  line-height: 36px;
  letter-spacing: 0.05em;
  opacity: 0;
  animation-name: fadeOutSplash;
  animation-duration: 3s;
}

.App-splash h4 {
  font: var(--regular-font);
  font-size: 36px;
  margin: 0;
}

.App-splash h2 {
  font: var(--heading-font);
  font-size: 36px;
  margin: 0;
}

.App-topBG{
  background-image: url(./assets/top-bg-vector.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 481px;
  width: 100%;
  position: absolute;
  top: 0;
}

.App-bottomBG{
  background-image: url(./assets/bottom-bg-vector.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 318px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

/* Splash Animation */
@keyframes fadeOutSplash {
  0%   {opacity: 1;}
  25%  {opacity: 0.75;}
  50%  {opacity:0.5;}
  75%  {opacity: 0.25;}
  100% {opacity: 0;}
}

/* Tablet */
@media only screen and (min-width: 768px) {

  .App-header {
    max-width: 738px;
    margin: 0 auto;
    height: 60px;
  }

  .App-header .logo {
    top: 0px;
  }

  .App-header .Action-btn {
    top: 16px;
  }

  .App-header .Secondary-Action-btn {
    top: 16px;
    right: 60px;
  }

  .App-header .Teritiary-Action-btn {
    top: 16px;
    right: 115px;
  }

  .App-splash {
    padding-top: 0px;
  }

  .App-topBG {
    background-image: url(./assets/top-bg-vector-med.png);
    height: 664px;
  }
  .App-bottomBG {
    background-image: url(./assets/bottom-bg-vector-med.png);
  }

}

/* Browsers */
@media only screen and (min-width: 1200px) {

  .App-header {
    display: block;
    text-align: left;
    max-width: 1228px;
    margin: 0 auto;
    height: 60px;
  }

  /* .App-header .Back-btn {
    display: none;
  } */

  .App-splash, .App-container {
    /*max-width: 1440px;*/
    min-height: 833px;
    margin: 0 auto;
  }

  .App-container {
    margin-bottom: 40px;
  }

  .App-splash{
    padding-top: 0px;
  }

  .App-topBG {
    background-image: url(./assets/top-bg-vector-large.png);
    height: 833px;
  }
  .App-bottomBG {
    background-image: url(./assets/bottom-bg-vector-large.png);
  }
}



